<template>
  <div>
    {{ data.metadata }}
    <section class="section is-medium">
      <div class="container">
        <h1 class="title">Generate a new image</h1>

        <div class="columns">
          <div class="column">
            <form method="post" action="/new-bot" class="searchFormHero" :style="{ maxWidth: '600px' }">
              <div class="field">
                <label class="label">Prompt</label>
                <div class="control">
                  <textarea
                    :value="data.prompt"
                    name="prompt"
                    class="textarea"
                    placeholder="Penguins on ice"
                  ></textarea>
                </div>
              </div>

              <div class="field">
                <label class="label">Seed</label>
                <div class="control">
                  <input :value="data.seed" name="seed" class="input" type="text" placeholder="123" />
                </div>
              </div>

              <div class="field">
                <label class="label">Width</label>
                <div class="control">
                  <input :value="data.width" name="width" class="input" type="text" placeholder="256" />
                </div>
              </div>

              <div class="field">
                <label class="label">Height</label>
                <div class="control">
                  <input :value="data.height" name="widtheighth" class="input" type="text" placeholder="256" />
                </div>
              </div>

              <div class="field is-grouped">
                <div class="control">
                  <button class="button is-link">Generate</button>
                </div>
                <div class="control">
                  <button class="button is-link is-light">Cancel</button>
                </div>
              </div>
            </form>
          </div>

          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  async mounted() {
    this.data = {}; //await useLoaderData();
  },
};
</script>
